import React from "react";
import { Grid, Typography } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import HistoryTwoToneIcon from '@material-ui/icons/HistoryTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';

export const Footer = (props) => {
    const { history } = props

    return (
        <Grid id='footer' container spacing={2} style={{textAlign: "center"}}>
            <Grid item xs={3} onClick={()=>history.push('/userprofile')}>
                <PersonOutlineTwoToneIcon /><br />
                <Typography>Profile</Typography>
            </Grid>
            <Grid item xs={3} onClick={()=>history.push('/History')}>
                <HistoryTwoToneIcon/><br />
                    <Typography>History</Typography>
            </Grid>
                <Grid item xs={3} onClick={()=>history.push('/Cart')}>
                <ShoppingCartIcon/><br />
                    <Typography>Cart</Typography>
                </Grid>
                <Grid item xs={3} onClick={()=>history.push('/Payment')}>
                    <AccountBalanceWalletTwoToneIcon /><br />
                    <Typography>Payment</Typography>
                </Grid>
            </Grid>
      //   <AppBar position="fixed" color="primary" className={classes.appBar}>
      //   <Toolbar>
      //     <IconButton edge="start" color="inherit" aria-label="open drawer">
      //       <MenuIcon />
      //     </IconButton>
      //     <Fab color="secondary" aria-label="add" className={classes.fabButton}>
      //       <AddIcon />
      //     </Fab>
      //     <div className={classes.grow} />
      //     <IconButton color="inherit">
      //       <SearchIcon />
      //     </IconButton>
      //     <IconButton edge="end" color="inherit">
      //       <MoreIcon />
      //     </IconButton>
      //   </Toolbar>
      // </AppBar>
    )
}


export default Footer
