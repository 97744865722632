import axios from "../axios";
export const AddressSetDefault = (AddressId, UserId) => {
  const url = "/profile/AddressSetDefault";
  const headers = {
    "Content-Type": "application/json",
    'x-access-token': window.localStorage.token
  };
  const data = {
    AddressId: AddressId,
    UserId: UserId
  };
  return axios({ method: "post", url, headers, data });
};